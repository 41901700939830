import '../scss/components/countdown-banner.scss';

export default {
    init() {
        const banner = document.querySelector(
            '.countdown-banner'
        ) as HTMLElement;
        const header = document.querySelector('header');

        if (!header || !banner) return;

        // show banner
        banner.classList.add('show-banner');

        const bannerHeight = banner.clientHeight;

        header.style.transform = 'translateY(' + bannerHeight + 'px)';

        // hide banner while scrolling on mobile
        if (window.innerWidth < 1024) {

            let lastScrollTop = 0;
            const delta = (banner.clientHeight*3);
            const navbarHeight = header.clientHeight;

            document.addEventListener('scroll', function(){
                const st = window.scrollY;

                if(Math.abs(lastScrollTop - st) <= delta)
                    return;
                if (st > lastScrollTop && st > navbarHeight){
                    // Scroll Down
                    header.style.transform = 'translateY(' + (-navbarHeight) + 'px)';
                    banner.style.transform = 'translateY(' + (-bannerHeight) + 'px)';
                } else {
                    // Scroll Up
                    if(st < lastScrollTop) {
                        header.style.transform = 'translateY(' + bannerHeight + 'px)';
                        banner.style.transform = 'translateY(' + 0 + 'px)';
                    }
                }
                lastScrollTop = st;
            });

        }

        const dateContainer = banner.querySelector(
            '.date-container'
        ) as HTMLElement;

        if (!dateContainer) return;

        const dueDate = dateContainer.dataset.date;
        const daysDiv = dateContainer.querySelector(
            '.days .number'
        ) as HTMLElement;
        const hoursDiv = dateContainer.querySelector(
            '.hours .number'
        ) as HTMLElement;
        const minutesDiv = dateContainer.querySelector(
            '.minutes .number'
        ) as HTMLElement;
        const secondsDiv = dateContainer.querySelector(
            '.seconds .number'
        ) as HTMLElement;

        if (!dueDate || !daysDiv || !hoursDiv || !minutesDiv || !secondsDiv)
            return;

        const dueDateTime = new Date(dueDate).getTime();

        if (!dueDateTime) return;

        udpateCountdown(daysDiv, hoursDiv, minutesDiv, secondsDiv);

        // call function each second
        setInterval(function () {
            udpateCountdown(daysDiv, hoursDiv, minutesDiv, secondsDiv);
        }, 1000);

        function udpateCountdown(
            daysDiv: HTMLElement,
            hoursDiv: HTMLElement,
            minutesDiv: HTMLElement,
            secondsDiv: HTMLElement
        ) {
            const now = new Date().getTime();
            const timeleft = dueDateTime - now;
            const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (timeleft % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

            daysDiv.innerHTML = days + '';
            hoursDiv.innerHTML = hours + '';
            minutesDiv.innerHTML = minutes < 10 ? '0' + minutes : minutes + '';
            secondsDiv.innerHTML = seconds < 10 ? '0' + seconds : seconds + '';
        }
    }
};
