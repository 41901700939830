export default {
    init() {
        const filterButtons = document.querySelectorAll(
            '.filter-button-blog'
        ) as NodeListOf<HTMLElement>;

        if (filterButtons.length) {
            filterButtons.forEach((button) => {
                button.addEventListener('click', function () {
                    const activeButtons = document.querySelectorAll(
                        '.filter-button-blog.active'
                    );

                    activeButtons.forEach((activeButton) => {
                        activeButton.classList.remove('active');
                    });

                    button.classList.add('active');
                });
            });
        }
    }
};
